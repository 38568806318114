@media (max-width: 575.98px) {
    #name {
        margin-top: 25px;
    }
}

@media (max-width: 767.98px) {
    #name {
        margin-top: 25px;
    }
}

body {
    --off-white: #F6f6f3;
    --primary-green: #579067;
    --primary-blue: #1282A3;
    --primary-dark-grey: #302E2b;
    --accent-red: #e30b5d;

    font-family: 'Titillium Web', sans-serif;

    background-color: #f6f5f3;

    --text-color: var(--primary-dark-grey);
    --menu-bg: var(--off-white);
    --border-color: var(--primary-dark-grey);
    --caption-color: var(--primary-dark-grey);

    &.dark {
        background-color: var(--primary-dark-grey);

        --text-color: var(--off-white);
        --menu-bg: var(--primary-dark-grey);
        --border-color: var(--off-white);
        --caption-color: var(--off-white);
    }

    color: var(--text-color);

}

html {
    overflow-y: scroll;
}

hr {
    border-top: 1px solid var(--border-color);
}

img {
    max-height: 100%;
    max-width: 100%;
}

.cover-image {
    -webkit-box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

figure {
    padding: 10px 0px;

    figcaption {
        text-align: center;
        padding: 15px 0;
        border-bottom: 1px solid var(--border-color);
        color: var(--caption-color) !important;
    }
}

h1,
h2,
h3 {
    color: var(--accent-red);

    &.green {
        color: var(--primary-green);
    }
}

h3 {
    font-family: 'Arvo', serif;
}

p {
    line-height: 1.66em;
    font-size: 1.1rem;
    margin-bottom: 25px;
    letter-spacing: .5px;
}

a {
    text-decoration: underline;
    color: var(--primary-blue);
}

#post-title-container {
    display: flex;
    flex-direction: column;

    #post-date {
        align-self: center;
    }
}

.input-wrapper {
    padding: 5px 5px 5px 0px;
}

#header {
    width: 100%;
}

#name {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

#name h1 {
    font-size: 4em;
    font-family: 'Yellowtail', cursive;
    transform: none;
    color: var(--primary-green);

    &.no-tilt {
        transform: none;
    }

    @media (min-width: 768px) { 
        transform: rotate(-5deg);
    }

}

#name h3 {
    color: var(--text-color);
    font-family: 'Arvo', serif;
}

#portrait {
    padding: 20px;
    border-radius: 100px;
}

.post-date {
    color: var(--accent-red);
    padding-right: 5px;
}

#post-title-grid {
    display: flex;
    flex-direction: row;

    .post-date {
        flex-basis: 125px;
        flex-grow: 0;
        padding-right: 10px;
        align-self: center;

        @media (max-width: 767.98px) {
            flex-basis: 75px;
            padding-right: 0px;
        }
    }

    .post-title {
        flex: 1;
        padding-left: 10px;
        border-left: 1px solid var(--border-color);
        line-height: 1.5em;
        color: var(--primary-blue);
        font-weight: bold;
        font-family: 'Arvo', serif;
    }
}

#menu {
    padding: 8px;

    &.sticky {
        background-color: var(--menu-bg);
        border-bottom: 1px solid var(--primary-green);
    }
}

#menu a {
    color: #579067;
    opacity: .5;
}

#menu a:hover {
    opacity: 1;
}

#content {
    margin-top: 25px;
}

#footer {
    margin-top: 100px;
    padding-bottom: 100px;
}

#change-mode {
    padding-left: 5px;
    cursor: pointer;
}

#title {
    color: var(--accent-red);
    text-decoration: underline;
    font-weight: bold;
    font-family: 'Arvo', serif;
}


.post-action {
    padding: 5px 0px 0px 0px;
}

.fade-in {
    opacity: 0;
}

.btn-primary {
    background-color: #365E85;
}

#heart {
    color: var(--accent-red);
}